(function() {
	var doc = $(document),
		win = $(window),
		scrollT = win.scrollTop(),
		dataAttr,
		header = $('[data-id="header"]'),
		footer = $('[data-id="footer"]'),
		docH, headerH, footerH;

	var waitForFinalEvent = (function () {
		var timers = {};
		return function (callback, ms, uniqueId) {
			if (!uniqueId) {
				uniqueId = "Don't call this twice without a uniqueId";
			}
			if (timers[uniqueId]) {
				clearTimeout (timers[uniqueId]);
			}
			timers[uniqueId] = setTimeout(callback, ms);
		};
	})();
	function setClassOnScroll() {
		if ( scrollT > 0 ) {
			$('.header').addClass('header_fixed');
		} else {
			$('.header').removeClass('header_fixed');
		}
	};
	function calcValue() {
		headerH = header.outerHeight();
		footerH = footer.outerHeight();
	};
	function clearClasses() {
		if ( win.width() > (768-18) ) {
			$('body').removeClass('scroll-hidden');
		}
	};
	function mobileFullPageToggle() {
		$('[data-open]').click(function() {
			dataAttr = $(this).attr('data-open');
			$('body').addClass('scroll-hidden')
			$('[data-id="' + dataAttr + '"]').addClass('visible show');
		})
		$('[data-close]').click(function() {
			dataAttr = $(this).attr('data-close');
			$('body').removeClass('scroll-hidden');
			$('[data-id="' + dataAttr + '"]').removeClass('show visible');
			$('[data-toggle="collapse"]').addClass('collapsed')
		})
	};
	function setScroll() {
		if ( win.width() >= (768-17) ) {
			$('[data-scroll="axisY"]').mCustomScrollbar({
				axis:"y",
				scrollEasing:"linear",
				scrollInertia:100,
				timeout:500
			});
		}
		if ( win.width() < (768-17) ) {
			$('[data-scroll="axisY"]').mCustomScrollbar("destroy");
		}
	}
	win.on('load resize scroll', function(e) {
		if ( e.type == 'load' ) {
		}
		if ( e.type == 'resize' ) {
			waitForFinalEvent(function() {
				calcValue();
				clearClasses();
				setScroll();
			}, 500, "resize")
		}
		if ( e.type == 'scroll' ) {
			scrollT = $(this).scrollTop();
			calcValue();
			setClassOnScroll();
		}
	})

	doc.on('click', function(e) {
		if ( e.type == 'click' ) {
			var target = $(e.target);
		}
	})
	doc.ready(function() {
		calcValue();
		setScroll();
		svg4everybody();
		clearClasses();
		mobileFullPageToggle();
		setClassOnScroll();
		$('[data-spy="scroll"]').scrollspy({
			offset: 80 || headerH
		});
		$(".nav-pills a").on('click', function(event) {
			headerH = parseInt($('.header').outerHeight())
			$('.menu').removeClass('visible');
			$('body').removeClass('scroll-hidden');
			if (this.hash !== "") {
				event.preventDefault();
				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerH
				}, 800, function(){
				});
			}
		});
	})
}())